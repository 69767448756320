@import '~antd/dist/antd.css';

.taskTable .ant-table-cell p {
  text-overflow: -o-ellipsis-lastlin;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  white-space: nowrap;
}
.min_task_modal {
  width: 500px;
  height: 88px;
  position: absolute;
  z-index: 1009;
  top: 0;
  left: 0;
  right: 0;
  margin: 20px auto 0 auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border: 1px solid #5274ff;
  padding: 17px;
}
.max_task_modal {
  position: absolute;
  z-index: 1009;
  top: 0;
  left: 0;
  right: 0;
  margin: 50px auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  width: 1056px;
  /* height: 590px; */
  background: #ffffff;
}
.task_modal_mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1008;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.7);
}
.max_zoom_btn {
  position: absolute;
  right: 22px;
  top: 17px;
  z-index: 2;
  cursor: pointer;
}
.min_zoom_btn {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2;
  cursor: pointer;
}
.max_task_modal .ant-form-item {
  margin-bottom: 0;
}
/* 进行中 */
.progress-underway {
  background: #26d49b;
  padding: 2px 18px;
  border-radius: 12px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
}
.progress-underway::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
/* 未开始、暂停中 */
.progress-leisure {
  background: #f2f2f2;
  padding: 2px 18px;
  color: #666666;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}
.progress-leisure::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #666666;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
/* 完成 */
.progress-complete {
  background: #5478fb;
  padding: 2px 18px;
  color: #ffffff;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}
.progress-complete::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.leisure-tip {
  color: #ff9900;
}
.underway-tip {
  color: #26d49b;
}
.complete-tip {
  color: #5478fb;
}
.call-task-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cursor{
  cursor: pointer;
}
.nowTaskProgress{
  background-color: #eef5fc;
}